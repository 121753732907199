function Main() {
  return (
    <div data-spy="scroll" data-target=".navbar-collapse">
      <div className='preloader'>
        <div className='loaded'>&nbsp;</div>
      </div>
      <header id="main_menu" className="header navbar-fixed-top">
        <div className="main_menu_bg">
          <div className="container">
            <div className="row">
              <div className="nave_menu">
                <nav className="navbar navbar-default" id="navmenu">
                  <div className="container-fluid">
                    <div className="navbar-header">
                      <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                              data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                      <a className="navbar-brand" href="index.html">
                        <img src="../assets/images/logo.png" alt=''/>
                      </a>
                    </div>

                    {/* <!-- Collect the nav links, forms, and other content for toggling -->*/}


                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                      <ul className="nav navbar-nav navbar-right">
                        <li><a href="aboutus.html">about us</a></li>
                        <li className="dropdown"><a href="#" className="dropdown-toggle" data-toggle="dropdown"
                                                role="button" aria-haspopup="true">services</a>
                          <ul className="dropdown-menu">
                            <li><a href="service.html">service page</a></li>
                            <li><a href="service1.html">service page two</a></li>
                          </ul>
                        </li>
                        <li className="dropdown"><a href="#" className="dropdown-toggle" data-toggle="dropdown"
                                                role="button" aria-haspopup="true">works</a>
                          <ul className="dropdown-menu">
                            <li><a href="work.html">work page</a></li>
                            <li><a href="work1.html">work page Two</a></li>
                          </ul>
                        </li>
                        <li className="dropdown"><a href="#" className="dropdown-toggle" data-toggle="dropdown"
                                                role="button" aria-haspopup="true">blog</a>
                          <ul className="dropdown-menu">
                            <li><a href="blog.html">blog page</a></li>
                            <li><a href="bloginner.html">singleblog page</a></li>
                          </ul>
                        </li>
                        <li><a href="contact.html">contact</a></li>
                      </ul>
                    </div>

                  </div>
                </nav>
              </div>
            </div>

          </div>

        </div>
      </header>
      {/*// <!--End of header -->*/}


      {/*// <!-- Home Section -->*/}
      <section id="home" className="home">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="main_home_slider text-center">


                <div className="single_home_slider">
                  <div className="home-overlay"></div>
                  <div className="main_home wow fadeInUp" data-wow-duration="700ms">

                    <h2>we are Defy🧡Distance</h2>
                    <div className="separator"></div>
                    <p className="subtitle">professionals <br/>
                      in the creative industries</p>
                    <div className="home_btn">
                      <a href="" className="btn">Learn More</a>
                    </div>
                  </div>
                </div>
                {/*// <!-- End of single_home_slider -->*/}

                <div className="single_home_slider">
                  <div className="home-overlay"></div>
                  <div className="main_home wow fadeInUp" data-wow-duration="700ms">
                    <h2>we are Defy🧡Distance</h2>
                    <div className="separator"></div>
                    <p className="subtitle">professionals <br/>
                      in the creative industries</p>
                    <div className="home_btn">
                      <a href="" className="btn">Learn More</a>
                    </div>
                  </div>
                </div>
                {/*// <!-- End of single_home_slider -->*/}

                <div className="single_home_slider">
                  <div className="home-overlay"></div>
                  <div className="main_home wow fadeInUp" data-wow-duration="700ms">
                    <h2>we are Defy🧡Distance</h2>
                    <div className="separator"></div>
                    <p className="subtitle">professionals <br/>
                      in the creative industries</p>
                    <div className="home_btn">
                      <a href="" className="btn">Learn More</a>
                    </div>
                  </div>
                </div>
                {/*// <!-- End of single_home_slider -->*/}

              </div>
            </div>
          </div>
        </div>
      </section>
      {/*// <!-- End of Home Section -->*/}


      {/*// <!-- Service Section -->*/}
      <section id="service" className="service sections lightbg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="head_title text-center">
                <h1>what we do?</h1>
              </div>
              {/*// <!-- End of head title -->*/}

              <div className="main_service_area">
                <div className="single_service_area">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="signle_service_left">
                        <img src="../assets/images/service0.png" alt=""/>
                      </div>
                    </div>
                    <div className="col-sm-5 col-sm-push-1">
                      <div className="single_service">
                        <h3>creative</h3>
                        <h2>kitchen</h2>
                        <div className="separator2"></div>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                          doloremque laudantium, totam rem aperiam,
                          eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
                          dicta sunt explicabo. </p>

                        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                          sed quia consequuntur magni dolores eos qui ratione voluptatem sequi
                          nesciunt.</p>

                        <a href="https://bootstrapthemes.co" className="btn btn-default">learn more</a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*// <!-- End of single service area -->*/}

                <div className="single_service_area  margin-top-80">
                  <div className="row">

                    <div className="col-sm-6 col-sm-push-6">
                      <div className="signle_service_left">
                        <img src="../assets/images/service2.png" alt=""/>
                      </div>
                    </div>

                    <div className="col-sm-5 col-sm-pull-6">
                      <div className="single_service">
                        <h3>art</h3>
                        <h2>installation</h2>
                        <div className="separator2"></div>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                          doloremque laudantium, totam rem aperiam,
                          eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
                          dicta sunt explicabo. </p>

                        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                          sed quia consequuntur magni dolores eos qui ratione voluptatem sequi
                          nesciunt.</p>

                        <a href="https://bootstrapthemes.co" className="btn btn-default">learn more</a>
                      </div>
                    </div>

                  </div>
                </div>
                {/*// <!-- End of single service area -->*/}

                <div className="single_service_area margin-top-80">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="signle_service_left">
                        <img src="../assets/images/service3.jpg" alt=""/>
                      </div>
                    </div>
                    <div className="col-sm-5 col-sm-push-1">
                      <div className="single_service">
                        <h3>print</h3>
                        <h2>design</h2>
                        <div className="separator2"></div>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                          doloremque laudantium, totam rem aperiam,
                          eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
                          dicta sunt explicabo. </p>

                        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                          sed quia consequuntur magni dolores eos qui ratione voluptatem sequi
                          nesciunt.</p>

                        <a href="https://bootstrapthemes.co" className="btn btn-default">learn more</a>
                      </div>
                    </div>
                  </div>
                </div>
                {/*// <!-- End of single service area -->*/}
              </div>
            </div>
            {/*// <!-- End of col-sm-12 -->*/}
          </div>
          {/*// <!-- End of row -->*/}
        </div>
        {/*// <!-- End of Container -->*/}
      </section>
      {/*// <!-- End of Service Section -->*/}

      {/*// <!-- Work Section -->*/}
      <section id="work" className="work sections">
        <div className="container">
          <div className="row">
            <div className="main_mix_content text-center">
              <div className="head_title">
                <h1>our best work</h1>
              </div>
              {/*// <!-- End of head title -->*/}

              <div id="mixcontent" className="mixcontent text-center">
                <div className="col-sm-4 col-xs-12">
                  <div className="single_mixi_portfolio">
                    <img src="../assets/images/pf01.png" alt=""/>
                    <div className="single_work_overlay">
                      <h4>golfino
                        awards</h4>
                      <div className="separator"></div>
                      <p>print design</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                  <div className="single_mixi_portfolio">
                    <img src="../assets/images/pf02.png" alt=""/>
                    <div className="single_work_overlay single_work_overlay2">
                      <h4>golfino
                        awards</h4>
                      <div className="separator"></div>
                      <p>print design</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                  <div className="single_mixi_portfolio">
                    <img src="../assets/images/pf3.png" alt=""/>
                    <div className="single_work_overlay">
                      <h4>golfino
                        awards</h4>
                      <div className="separator"></div>
                      <p>print design</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-xs-12">
                  <div className="single_mixi_portfolio">
                    <img src="../assets/images/pf4.png" alt=""/>
                    <div className="single_work_overlay single_work_overlay2">
                      <h4>golfino
                        awards</h4>
                      <div className="separator"></div>
                      <p>print design</p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-xs-12">
                  <div className="single_mixi_portfolio">
                    <img src="../assets/images/pf6.png" alt=""/>
                    <div className="single_work_overlay single_work_overlay2">
                      <h4>golfino
                        awards</h4>
                      <div className="separator"></div>
                      <p>print design</p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-xs-12">
                  <div className="single_mixi_portfolio">
                    <img src="../assets/images/pf5.png" alt=""/>
                    <div className="single_work_overlay">
                      <h4>golfino
                        awards</h4>
                      <div className="separator"></div>
                      <p>print design</p>
                    </div>
                  </div>
                </div>
              </div>

              <a href="https://bootstrapthemes.co" className="btn">learn more</a>
            </div>
          </div>
          {/*// <!-- End of row -->*/}
        </div>
        {/*// <!-- End of Contaier -->*/}
      </section>
      {/*// <!-- End of portfolio Section -->*/}


      {/*// <!-- Team Section -->*/}
      <section id="team" className="team colorsbg sections">
        <div className="container">
          <div className="row">
            <div className="main_team_area">
              <div className="head_title text-center">
                <h1>best team</h1>
              </div>
              {/*// <!-- End of head title -->*/}

              <div className="main_team text-center">
                <div className="single_team">
                  <div className="col-sm-10 col-sm-offset-1">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="team">
                          <img className="img-circle" src="../assets/images/team1.jpg" alt=""/>
                          <h4>paul hall</h4>
                          <div className="separator"></div>
                          <p>art director</p>
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-4 -->*/}
                      <div className="col-sm-4">
                        <div className="team">
                          <img className="img-circle" src="../assets/images/team2.jpg" alt=""/>
                          <h4>joshua spencer</h4>
                          <div className="separator"></div>
                          <p>founder</p>
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-4 -->*/}
                      <div className="col-sm-4">
                        <div className="team">
                          <img className="img-circle" src="../assets/images/team3.jpg" alt=""/>
                          <h4>diane lewis</h4>
                          <div className="separator"></div>
                          <p>artist, designer</p>
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-4 -->*/}
                    </div>
                  </div>
                  {/*// <!-- End of col-sm-10 -->*/}
                </div>
                <div className="single_team">
                  <div className="col-sm-10 col-sm-offset-1">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="team">
                          <img className="img-circle" src="../assets/images/team1.jpg" alt=""/>
                          <h4>paul hall</h4>
                          <div className="separator"></div>
                          <p>art director</p>
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-4 -->*/}
                      <div className="col-sm-4">
                        <div className="team">
                          <img className="img-circle" src="../assets/images/team1.jpg" alt=""/>
                          <h4>joshua spencer</h4>
                          <div className="separator"></div>
                          <p>founder</p>
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-4 -->*/}
                      <div className="col-sm-4">
                        <div className="team">
                          <img className="img-circle" src="../assets/images/team1.jpg" alt=""/>
                          <h4>diane lewis</h4>
                          <div className="separator"></div>
                          <p>artist, designer</p>
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-4 -->*/}
                    </div>
                  </div>
                  {/*// <!-- End of col-sm-10 -->*/}
                </div>
                <div className="single_team">
                  <div className="col-sm-10 col-sm-offset-1">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="team">
                          <img className="img-circle" src="../assets/images/team1.jpg" alt=""/>
                          <h4>paul hall</h4>
                          <div className="separator"></div>
                          <p>art director</p>
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-4 -->*/}
                      <div className="col-sm-4">
                        <div className="team">
                          <img className="img-circle" src="../assets/images/team1.jpg" alt=""/>
                          <h4>joshua spencer</h4>
                          <div className="separator"></div>
                          <p>founder</p>
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-4 -->*/}
                      <div className="col-sm-4">
                        <div className="team">
                          <img className="img-circle" src="../assets/images/team1.jpg" alt=""/>
                          <h4>diane lewis</h4>
                          <div className="separator"></div>
                          <p>artist, designer</p>
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-4 -->*/}
                    </div>
                  </div>
                  {/*// <!-- End of col-sm-10 -->*/}
                </div>
              </div>
            </div>
          </div>
          {/*// <!-- End of row -->*/}
        </div>
        {/*// <!-- End of Contaier -->*/}
      </section>
      {/*// <!-- End of portfolio Section -->*/}


      {/*// <!-- Clients Section -->*/}
      <section id="clients" className="clients sections">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="main_clients_area text-center">
                <div className="head_title">
                  <h1>clients say’s</h1>
                </div>
                {/*// <!-- End of head title -->*/}

                <div id="example3" className="slider-pro">
                  <div className="sp-slides sps_slider">
                    <div className="sp-slide">
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-sm-6 col-xs-6">
                            <div className="single_left_text text-right">
                              <h4 className="sp-layer" data-horizontal="right" data-vertical="0"
                                  data-width="81%" data-show-transition="left"
                                  data-show-delay="200">
                                frank sims
                              </h4>

                              <div className="separator sp-layer sp-black" data-horizontal="340"
                                   data-vertical="70"></div>

                              <p className="sp-layer" data-horizontal="right" data-vertical="100"
                                 data-width="81%" data-show-transition="left"
                                 data-show-delay="400">
                                photographer
                              </p>

                            </div>
                          </div>
                          {/*// <!-- End of col-sm-6 -->*/}
                          <div className="col-sm-6 col-xs-6">
                            <div className="single_right_text text-left">
                              <p className="sp-layer right_sp_layer" data-width="100%">Sed ut
                                perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium,
                                totam rem aperiam, eaque ipsa quae ab illo inventore veritatis
                                et quasi architecto beatae vitae dicta sunt explicabo. </p>
                            </div>
                          </div>
                          {/*// <!-- End of col-sm-6 -->*/}
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-12 -->*/}
                    </div>
                    <div className="sp-slide">
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-sm-6 col-xs-6">
                            <div className="single_left_text text-right">
                              <h4 className="sp-layer" data-horizontal="right" data-vertical="0"
                                  data-width="81%" data-show-transition="left"
                                  data-show-delay="200">
                                frank sims
                              </h4>

                              <div className="separator sp-layer sp-black" data-horizontal="340"
                                   data-vertical="70"></div>

                              <p className="sp-layer" data-horizontal="right" data-vertical="100"
                                 data-width="81%" data-show-transition="left"
                                 data-show-delay="400">
                                photographer
                              </p>

                            </div>
                          </div>
                          {/*// <!-- End of col-sm-6 -->*/}
                          <div className="col-sm-6 col-xs-6">
                            <div className="single_right_text text-left">
                              <p className="sp-layer right_sp_layer" data-width="100%">Sed ut
                                perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium,
                                totam rem aperiam, eaque ipsa quae ab illo inventore veritatis
                                et quasi architecto beatae vitae dicta sunt explicabo. </p>
                            </div>
                          </div>
                          {/*// <!-- End of col-sm-6 -->*/}
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-12 -->*/}
                    </div>
                    <div className="sp-slide">
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-sm-6 col-xs-6">
                            <div className="single_left_text text-right">
                              <h4 className="sp-layer" data-horizontal="right" data-vertical="0"
                                  data-width="81%" data-show-transition="left"
                                  data-show-delay="200">
                                frank sims
                              </h4>

                              <div className="separator sp-layer sp-black" data-horizontal="340"
                                   data-vertical="70"></div>

                              <p className="sp-layer" data-horizontal="right" data-vertical="100"
                                 data-width="81%" data-show-transition="left"
                                 data-show-delay="400">
                                photographer
                              </p>

                            </div>
                          </div>
                          {/*// <!-- End of col-sm-6 -->*/}
                          <div className="col-sm-6 col-xs-6">
                            <div className="single_right_text text-left">
                              <p className="sp-layer right_sp_layer" data-width="100%">Sed ut
                                perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium,
                                totam rem aperiam, eaque ipsa quae ab illo inventore veritatis
                                et quasi architecto beatae vitae dicta sunt explicabo. </p>
                            </div>
                          </div>
                          {/*// <!-- End of col-sm-6 -->*/}
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-12 -->*/}
                    </div>
                    <div className="sp-slide">
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-sm-6 col-xs-6">
                            <div className="single_left_text text-right">
                              <h4 className="sp-layer" data-horizontal="right" data-vertical="0"
                                  data-width="81%" data-show-transition="left"
                                  data-show-delay="200">
                                frank sims
                              </h4>

                              <div className="separator sp-layer sp-black" data-horizontal="340"
                                   data-vertical="70"></div>

                              <p className="sp-layer" data-horizontal="right" data-vertical="100"
                                 data-width="81%" data-show-transition="left"
                                 data-show-delay="400">
                                photographer
                              </p>

                            </div>
                          </div>
                          {/*// <!-- End of col-sm-6 -->*/}
                          <div className="col-sm-6 col-xs-6">
                            <div className="single_right_text text-left">
                              <p className="sp-layer right_sp_layer" data-width="100%">Sed ut
                                perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium,
                                totam rem aperiam, eaque ipsa quae ab illo inventore veritatis
                                et quasi architecto beatae vitae dicta sunt explicabo. </p>
                            </div>
                          </div>
                          {/*// <!-- End of col-sm-6 -->*/}
                        </div>
                      </div>
                      {/*// <!-- End of col-sm-12 -->*/}
                    </div>
                  </div>

                  <div className="sp-thumbnails">
                    <img className="sp-thumbnail" src="../assets/images/c1.png"/>
                    <img className="sp-thumbnail" src="../assets/images/c1.png"/>
                    <img className="sp-thumbnail" src="../assets/images/c1.png"/>
                    <img className="sp-thumbnail" src="../assets/images/c1.png"/>
                  </div>
                </div>
              </div>
            </div>
            {/*// <!-- End of col-sm-12 -->*/}
          </div>
          {/*// <!-- End of row -->*/}
        </div>
        {/*// <!-- End of Contaier -->*/}
      </section>
      {/*// <!-- End of portfolio Section -->*/}


      {/*// <!-- Contact Section -->*/}
      <section id="contact" className="contact">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="main_contact sections">
                <div className="head_title text-center whitetext">
                  <h1>get in touch</h1>
                </div>
                {/*// <!-- End of head title -->*/}

                <div className="row">
                  <div className="contact_contant">
                    <div className="col-sm-6 col-xs-12">
                      <div className="single_message_right_info whitetext">
                        <ul>
                          <li><i className="fa fa-map-marker"></i> <span>23 Mulholland Drive, Suite 721.
                                                    Los Engels 10010
                                                    100 S. Main Street. Los Engels 90012</span></li>

                          <li><i className="fa fa-mobile-phone"></i> <span>+1-670-567-5590</span></li>

                          <li><i className="fa fa-envelope-o"></i> <span>hello@DefyDistance.ru</span>
                          </li>
                        </ul>

                        <div className="contact_socail transition">
                          <a href=""><i className="fa fa-facebook img-circle"></i></a>
                          <a href=""><i className="fa fa-twitter img-circle"></i></a>
                          <a href=""><i className="fa fa-google-plus img-circle"></i></a>
                          <a href=""><i className="fa fa-pinterest img-circle"></i></a>
                          <a href=""><i className="fa fa-instagram img-circle"></i></a>
                        </div>
                      </div>
                    </div>
                    {/*// <!-- End of col-sm-6 -->*/}

                    <div className="col-sm-6 col-xs-12">
                      <div className="single_contant_left margin-top-60">
                        <form action="#" id="formid">
                          {/*// <!--<div className="col-lg-8 col-md-8 col-sm-10 col-lg-offset-2 col-md-offset-2 col-sm-offset-1">-->*/}

                          <div className="form-group">
                            <label>name</label>
                            <input type="text" className="form-control" name="name" required=""></input>
                          </div>

                          <div className="form-group">
                            <label>e-mail</label>
                            <input type="email" className="form-control" name="email" required=""></input>
                          </div>

                          <div className="form-group">
                            <label>your message</label>
                            <textarea className="form-control" name="message" rows="8"></textarea>
                          </div>

                          <div className="">
                            <input type="submit" value="submit" className="btn btn-default"></input>
                          </div>
                          {/*// <!--</div>-->*/}
                        </form>
                      </div>
                    </div>
                  </div>
                  {/*// <!-- End of messsage contant-->*/}
                </div>
              </div>
            </div>
            {/*// <!-- End of col-sm-12 -->*/}
          </div>
          {/*// <!-- End of row -->*/}
        </div>
        {/*// <!-- End of Contaier -->*/}
      </section>
      {/*// <!-- End of portfolio Section -->*/}


      {/*// <!-- footer Section -->*/}
      <footer id="footer" className="footer">
        <div className="container">
          <div className="main_footer">
            <div className="row">
              <div className="col-sm-6">
                <div className="copyright_text text-center">
                  <p className=" wow fadeInRight" data-wow-duration="1s">Made with <i className="fa fa-heart"></i> by
                    <a target="_blank" href="https://bootstrapthemes.co">Bootstrap Themes</a>2016. All
                    Rights Reserved
                  </p>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="copyright_text text-center">
                  <p className=" wow fadeInRight" data-wow-duration="1s">Distributed <i className="fa fa-heart"></i>
                    by
                    <a target="_blank" href="https://themewagon.com/">Themewagon</a>2016. All
                    Rights Reserved
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/*// <!-- End of container -->*/}
      </footer>
      {/*// <!-- End of footer -->*/}


      <div className="scrollup">
        <a href="#"><i className="fa fa-chevron-up"></i></a>
      </div>
    </div>
  );
}

export default Main;
